<template lang="pug">
  .content.background
    .row.body-login
      .col-md-12.body-login-mobile
        div.lg-img.text-center
          img.lg-img(:src="logo" style="height:160px;")
        .card.lg-body
          
          .card-content.lg-custom-card-content
            div.col-md-12
              .alert.alert-danger(v-if="errors")
                .txt-error {{errors}}
            div.form-group.row(style="margin:0;")
              div.col-md-12
                .form-group.custom-form-group(style='position:relative;margin-top:10px;')
                  input#username.form-control(class="form-custom" type="text" name="email" placeholder="Tên đăng nhập" required style='padding-right: 40px !important;' autofocus v-model="email" autocomplete="off")
                  i.material-icons.lg-icon-account account_circle
            div.form-group.row(style="margin:0;")
              div.col-md-12
                .form-group.custom-form-group(style='position:relative;margin-top:10px;')
                  input#password.form-control(class="form-custom" v-model="password" :type='passwordType' placeholder="Mật khẩu" required style='padding-right: 40px !important;' v-on:keyup="validateEmailAddress")
                  i.material-icons.lg-icon-password(@click="switchVisibility" v-if="passwordType === 'text'") visibility
                  i.material-icons.lg-icon-password(@click="switchVisibility" v-else) visibility_off
            div.col-md-12
              a(href="#" @click="forgotPassword" ) Quên mật khẩu
            div.form-group.row.mb-0.text-center(style='margin-top: 2px;')
              div.col-md-12
                button.btn.btn-custom(@click='submitFormLogin') Đăng nhập

</template>

<script>
//import firebase from 'firebase'
//import configJson from '../../../config'
//import store from "@/store";
//import {onLogout} from "@/vue-apollo";
import Cryptojs from 'crypto-js'
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      errors: null,
      passwordType: 'password',
      countSubmit: 0,
      logo: '../../../assets/logo_img.png',
    }
  },
  apollo: {
  },
  async mounted() {
    window.scrollTo(0, 0)
    var that =this;
   var token = (await this.$AuthenticationApp.getToken());
    if (!this.$commonFuction.isEmpty(token)){
      this.$AuthenticationApp.checkToken(token).then(async (res) => {
        if (res){
          //token còn hạn
          that.$router.push({path: that.$constants.URL_ERR.REDIRECT});
        }
      })
    }
    },
  methods: {
    forgotPassword() {
      alert("Vui lòng liên hệ admin hệ thống");
    },
    switchVisibility() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    validateEmailAddress(e) {
      if (e.keyCode === 13) this.submitFormLogin()
      else if (e.keyCode === 27) this.password = ''
    },
    submitFormLogin() {
      if (this.$commonFuction.isEmpty(this.email)){
        this.errors = 'Tên đăng nhập hoặc email không được bỏ trống';
        return false;
      }
      if (this.$commonFuction.isEmpty(this.password)){
        this.errors = 'Vui lòng nhập mật khẩu!';
        return false;
      }
        const user = {
          username: this.email,
          password: (Cryptojs.SHA256(this.password.toString())).toString()
        }
        var that = this;
        that.$commonFuction.is_loading(true);
        this.$store.dispatch('signInAction', user).then(async () => {
            that.$router.push({path: that.$constants.URL_ERR.REDIRECT});
            that.$commonFuction.is_loading(false);
        }).catch((error) => {
          //this.errorMessage = error.message;
          var message = error.response.data.message;
          //console.error("There was an error!", error);
          switch(message) {
            case this.$constants.MSG_ERROR.ACCOUNT_IS_NOT_EXISTED_ERR:
              this.errors = that.$constants.MSG_TEXT.ACCOUNT_IS_NOT_EXISTED_ERR;
              that.$commonFuction.is_loading(false);
              break;
            case this.$constants.MSG_ERROR.MISMATCH_PARAMS_ERR:
              this.errors = that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN;
              that.$commonFuction.is_loading(false);
              break;
            case this.$constants.MSG_ERROR.ACCOUNT_IS_DEACTIVE_ERR:
              this.errors = that.$constants.MSG_TEXT.ACCOUNT_IS_DEACTIVE_ERR;
              that.$commonFuction.is_loading(false);
              break;
            case this.$constants.MSG_ERROR.INVALID_CURRENT_PASSWORD_ERR:
              this.errors = that.$constants.MSG_TEXT.INVALID_CURRENT_PASSWORD_ERR;
              that.$commonFuction.is_loading(false);
              break;
            default:
              this.errors = that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN;
              that.$commonFuction.is_loading(false);
          }
        })

    }
  }
}
</script>

<style lang="scss">

// .login-background {}

// body {
//   background-image: url('/assets/images/background-admin.png');
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: cover;
// }
:root {
  --color-lg-fb: #3b5998;
  --color-lg-gg: #da4837;
  --color-lg-yellow: #ffd311;
}
.body-login { width: 595px; margin: 13% auto; 
  .lg-img {
    background: transparent; 
    margin-bottom: 10px;}
  .lg-body {
    margin-left: 0%;
    margin-top: 0;
    width: 584px;
    height: auto;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
    padding-top: 0px;
    .lg-custom-card-content {
      padding-top: 0;
      padding-left: 10px;
      padding-right: 10px;
      .line-strike {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #b4b4b4;
        line-height: 0.1em;
        margin: 0px 0px;
        span {
          background: #fff;
          padding: 0 10px;
          color: #b4b4b4;
          font-family: "adobe-clean", sans-serif;
        }
      }
      .custom-btn-fb {
        background-color: var(--color-lg-fb);
        color: white;
        font-size: 15px;
        font-family: "adobe-clean", sans-serif;
        text-align: center;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        display: block;
        width: 190x;
        height: 50px;
        margin-left: 50px;
        padding-top: 15px;
        cursor: pointer;
      }
      .custom-btn-gg {
        background-color: var(--color-lg-gg);
        color: white;
        font-size: 15px;
        // font-weight: bold;
        font-family: "adobe-clean", sans-serif;
        text-align: center;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        display: block;
        width: 190x;
        height: 50px;
        margin-left: 50px;
        padding-top: 15px;
        cursor: pointer;
      }
      .lg-icon-password {
        margin-top: -30px;
        display: block;
        position: absolute;
        right: 10px;
        padding: auto 5px;
        &:hover {
          cursor: pointer;
        }
      }
      .lg-btn-save {
        background: var(--color-lg-yellow);
        color: black;
        text-transform: none;
        font-size: 14px;
        font-family: "adobe-clean", sans-serif;
        border: 1px solid #ffd311;
        border-radius: 5px;
        // box-shadow: 0px 0px 0px;
        margin-top: 0px !important;
        width: 100%;
      }
      .lg-reg-pull-right {
        float: right;
      }
      .lg-forgot-password {
        font-family: "adobe-clean", sans-serif;
        &:hover {
          cursor: pointer;
        }
      }
      .lg-reg-now {
        font-family: "adobe-clean", sans-serif;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.form-custom{
  padding-right: 42.5px;
  border-radius: 0 !important;
  box-shadow: none;
  border-color: #d2d6de;
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  font-family: inherit;
}
.lg-icon-account{
  margin-top: -30px;
  display: block;
  position: absolute;
  right: 10px;
}
.btn-custom{
  height: 35px !important;
  background: linear-gradient(to right,#007bff 0%, #007bff 100%) !important;
  border: 1px solid #007bff !important;
  margin-top: 8px !important;
  cursor: pointer !important;
  color: white;
  text-transform: none;
  font-size: 14px;
  font-family: "adobe-clean", sans-serif;
  border-radius: 5px;
  width: 100%;
}
</style>
